import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
// import Banner from "../components/banner"
import Hero from "../components/hero"
import ListedContent from "../components/ListedContent"
// import Mosaic from "../components/mosaic"
import Layout from "../layouts/index"

// import Carousel from '../lib/carousel'
const HomePage = ({data}) => {
  
  return(
    <Layout>
      <Hero data={data}></Hero>  
      <ListedContent props={data.mdx.frontmatter.grid_content_one}></ListedContent>  
    </Layout>
  )
} 
export default HomePage 

export const pageQuery = graphql`
query serviceQuery {
  
  mdx(slug: {eq: "services"}) {
    frontmatter {
      title
      description
      hero_image_one {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, width: 900)
        }
      }
      hero_image_two {
          childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, width: 900)
        }
      }
      grid_content_one {
        grid_col
        grid_width
        blocks {
          copy
          link_url
          title
          link_text
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, width: 800)
            }
          }
        }
      }
    }
  }
}`