import * as React from "react"
import Arrow from './arrow'
import { useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const ListedContent = ({props}) => {

const { scrollYProgress } = useViewportScroll()

  return (
    
    <motion.ul 
    animate={ { opacity: 1 } }
    initial={ {opacity: 0 } }
    transition={ { delay: .45, duration: .45 } }
    className="listed-content" >
    
        {props.blocks.map((block, index) =>
          <motion.li className="list-block">
     
              {block.Header !== '' &&  
              <h3>{block.title}</h3>}

              {props.gridColumns === 'split' && <div className="paragraph-x-large">
              <ReactMarkdown>{block.copy}</ReactMarkdown>   
              </div>}
              
            {block.linkText !== '' && 
            <a href={block.linkUrl} className="text-cta"><Arrow />{block.linkText}</a>}
        
          </motion.li>
        )}

      </motion.ul>
  ) 
}
export default ListedContent


